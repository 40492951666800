<template>
  <div
  >
    <b-modal id="modal-vip" hide-footer>
      <template #modal-title>VIP</template>
      <div class="d-block text-center">
        <div class="card-body p-0">
          <div class="row justify-content-center mb-4 text-shadow">
            <div class="col-sm-6 col-md-4">
              <div class=" ">
                <div class="card-body p-0">
                  <p class="card-text text-center">
                    <strong>คะแนนของคุณ</strong>
                  </p>
                  <h6 class="card-title text-center">{{ numberWithCommas(balance) }}</h6>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class=" ">
                <div class="card-body  p-0">
                  <p class="card-text text-center">
                    <strong>สถานะของคุณ</strong>
                  </p>
                  <h6 class="card-title text-center">VIP {{ vip_level }}</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="row no-gutters mb-3" v-for="item in vip_credit" :key="item.id">
            <div class="col-3 text-right">{{ item.name }}</div>
            <div class="col-5" style="padding-left: 10px; padding-right: 10px">
              <b-progress :value="(credit_total / item.credit) * 100" :max="100" class=""></b-progress>
            </div>
            <div class="col-4">{{ numberWithCommas(item.credit) }}</div>
          </div>
          <div class="text-center text-primary">
            <i class="fa fa-info-circle"></i> Jackpot รับเงิน 1,000,000 บาท
          </div>
        </div>
      </div>
      <b-button
        variant="primary"
        class="btn btn btn-primary mt-3"
        block
        @click="$bvModal.hide('modal-vip')"
        >ฉันเข้าใจและยอมรับ</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    balance: {
      type: String,
      default: '0.00'
    },
    username: {
      type: String,
      default: ''
    },
    credit_total: {
      default: 0
    },
    vip_level: {
      default: 0
    }
  },
  data () {
    return {
      vip_credit: [
        { name: 'VIP 1', credit: 10000 },
        { name: 'VIP 2', credit: 100000 },
        { name: 'VIP 3', credit: 500000 },
        { name: 'VIP 4', credit: 1000000 },
        { name: 'VIP 5', credit: 10000000 },
        { name: 'Jackpot', credit: 100000000 }
      ]
    }
  },
  methods: {
    ...mapActions(['getBalanceInfo', 'setLoading']),
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  computed: {
    ...mapGetters(['getIsVip'])
  }
}
</script>

<style>
</style>
