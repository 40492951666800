<template>
  <div class="headermember" v-if="this.$route.meta.isNoRequestFooter ? !(this.$route.meta.isNoRequestFooter == true) : true">
    <div class="sticky-top">
      <div class="   p-0">
        <nav class="x-header navbar topBar-holder  navbar-expand-lg -anon p-0">
          <div class="container-fluid align-items-center h-100 position-relative headerMember">
            <div id="headerBrand" class="pl-2">
              <router-link to="/" class="navbar-brand" rel="home" aria-current="page"><img :src="require('@/assets/images/'+getLogo1)" class="-logo" alt="สล็อต เว็บสล็อตออนไลน์ สล็อตเว็บตรงแตกง่ายเว็บใหญ่ที่สุด ไม่ผ่านเอเย่นต์"  ></router-link> <!-- <div class="-heading-title"></div> -->
            </div>
            <div id="headerContent">
              <div class="navbar-na ml-3">
                <div class="d-flex x-anon ">
                  <ul class="nav -menu-wrapper"  v-if="isMember">
                  <li class="nav-item"  >
                    <router-link :to="hasBank ? '/member/deposit' : '/member/settings/bank/add'" class="nav-link  nav-link2"   :class="{ 'nav-link-active ': isPageActive('member/deposit') }" >
                      <div class="-img-wrapper">
                        <img src="@/assets/icon/deposit.png" class="-icon" alt="Menu icon promotion" width="30" height="30">
                      </div>
                      <div class="-text">ฝากเงิน</div>
                    </router-link>
                  </li>
                </ul>
                <ul class="nav -menu-wrapper"  v-if="isMember">
                  <li class="nav-item">
                    <router-link :to="hasBank ? '/member/withdraw' : '/member/settings/bank/add'" class="nav-link  nav-link2"   :class="{ 'nav-link-active ': isPageActive('member/withdraw') }" >
                      <div class="-img-wrapper">
                        <img src="@/assets/icon/money-withdrawal.png"  class="-icon" alt="Menu icon promotion" width="30" height="30">
                      </div>
                      <div class="-text">ถอนเงิน</div>
                    </router-link>
                  </li>
                </ul>
                <ul class="nav -menu-wrapper"  v-if="isMember">
                  <li class="nav-item">
                    <router-link to="/member/statement" class="nav-link  nav-link2"   :class="{ 'nav-link-active ': isPageActive('statement') }">
                      <div class="-img-wrapper">
                        <img src="@/assets/icon/statement.png" class="-icon" alt="Menu icon promotion" width="30" height="30">
                      </div>
                      <div class="-text">เครดิต</div>
                    </router-link>
                  </li>
                </ul>
                <ul class="nav -menu-wrapper"  v-if="isMember">
                  <li class="nav-item">
                    <router-link to="/member/request/all" class="nav-link  nav-link2"   :class="{ 'nav-link-active ': isPageActive('memberrequest') }">
                      <div class="-img-wrapper">
                        <img src="@/assets/icon/money-bag.png" class="-icon" alt="Menu icon promotion" width="30" height="30">
                      </div>
                      <div class="-text">ฝากถอน</div>
                    </router-link>
                  </li>
                </ul>
                <ul class="nav -menu-wrapper"  v-if="isMember">
                  <li class="nav-item">
                    <router-link to="/member/affiliate" class="nav-link  nav-link2"   :class="{ 'nav-link-active ': isPageActive('affiliate/members') }">
                      <div class="-img-wrapper">
                        <img src="@/assets/icon/salary.png" class="-icon" alt="Menu icon promotion" width="30" height="30">
                      </div>
                      <div class="-text">แนะนำเพื่อน</div>
                    </router-link>
                  </li>
                </ul>
                <ul class="nav -menu-wrapper"  v-if="isMember">
                  <li class="nav-item">
                    <router-link to="/member/poy" class="nav-link  nav-link2"   :class="{ 'nav-link-active ': isPageActive('poy') }">
                      <div class="-img-wrapper">
                        <img src="@/assets/icon/ticket.png" class="-icon" alt="Menu icon promotion" width="30" height="30">
                      </div>
                      <div class="-text">รายการโพย</div>
                    </router-link>
                  </li>
                </ul>
                <ul class="nav -menu-wrapper"  v-if="isMember">
                  <li class="nav-item" >
                    <router-link to="/member/result/all" class="nav-link  nav-link2"   :class="{ 'nav-link-active ': isPageActive('games/history') }">
                      <div class="-img-wrapper">
                        <img src="@/assets/icon/lottery.png" class="-icon" alt="Menu icon promotion" width="30" height="30">
                      </div>
                      <div class="-text">ผลรางวัล</div>
                    </router-link>
                  </li>
                </ul>
                <!-- <ul class="nav -menu-wrapper" >
                  <li class="nav-item">
                    <a href="javascript:;"  @click="openLine()" class="nav-link nav-link2"  :class="{ 'nav-link-active ': isPageActive('contect') }">
                      <div class="-img-wrapper ">
                        <img src="@/assets/icon/line2.png" class="-icon" alt="Menu icon event" width="30" height="30">
                      </div>
                      <div class="-text">ติดต่อเรา</div>
                    </a>
                  </li>
                </ul> -->
                <!-- register-verify v-if="!(this.$route.name.isNoRequestFooter == true)" -->
                <div class="button button-login mr-1" v-b-modal.modal-user-login v-if="!isMember">ลงชื่อเข้าใช้</div>
                <div class="button button-signup mr-1" v-b-modal.modal-user-register  v-if="(!isMember) && (this.$route.name != 'register-verify')">สมัครสมาชิก</div>
                <!-- <router-link class="button button-signup mr-1" to="/register-verify" v-if="!isMember">ลงทะเบียน </router-link> -->
                  <!-- <ul class="nav -menu-wrapper  d-block"  v-if="!isMember">
                    <li class="nav-item">
                      <a href="javascript:;" class="nav-link nav-link2" v-b-modal.modal-user-login>
                        <div class="-img-wrapper">
                          <img src="@/assets/icon/login.png" class="-icon" alt="Menu icon promotion" width="30" height="30">
                        </div>
                        <div class="-text">เข้าสู่ระบบ</div>
                      </a>
                    </li>
                  </ul>
                  <ul class="nav -menu-wrapper  d-block"  v-if="!isMember">
                    <li class="nav-item">
                      <router-link to="/register-verify" class="nav-link nav-link2" >
                        <div class="-img-wrapper">
                          <img src="@/assets/icon/register.png" class="-icon" alt="Menu icon promotion" width="30" height="30">
                        </div>
                        <div class="-text">สมัครสมาชิก</div>
                      </router-link>
                    </li>
                  </ul> -->
                  <ul class="nav -menu-wrapper  d-block" v-if="isMember">
                    <li class="nav-item">
                      <a href="javascript:;" class="nav-link nav-link2 pt-2 pb-2  logo_user_nav"  @click="setIsActive(true)" >
                        <div class="-img-wrapper">
                          <img  src="@/assets/icon/user_2.png" alt="" onerror="this.src='@/assets/images/user-128.png'" class="rounded-circle" width="30" height="30" />
                            <span class="badge badge-pill badge-light thb -log-blink" data-id="credit_balance">{{ getBalance }} ฿</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <app-callcenter-modal />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CallCenterModal from '@/components/modals/CallCenterModal'
import { checkCreditTurn } from '@/services'
import { STATUS_SUCCESS } from '@/constants'
export default {
  components: {
    'app-callcenter-modal': CallCenterModal
  },
  data: () => {
    return {
      language: localStorage.getItem('locale') ?? 'th',
      AGENT_EXT_NAME: process.env.VUE_APP_EXT_NAME
    }
  },
  async mounted () {
    console.log(this.$route.meta.isNoRequestFooter)
    await this.reqConfig({
      id: process.env.VUE_APP_AG_ID,
      afKey: this.afKey,
      agKey: this.agKey
    })
    if (this.isMember) {
      console.log('this.hasBank')
      console.log(this.hasBank)
      if (this.hasBank === null) {
        this.getMyAccountBanks()
      }
    }
    // await this.reqConfig(process.env.VUE_APP_AG_ID)
  },
  methods: {
    ...mapActions(['reqConfig', 'setIsActive', 'clearLogOut', 'setLoading', 'getMyAccountBanks']),
    selectLangauge (lang) {
      this.language = lang
      this.$i18n.locale = lang?.toLowerCase()
      localStorage.setItem('locale', lang?.toLowerCase())
    },
    onLogout () {
      this.setIsActive(false)
      this.setLoading(true)
      this.clearLogOut()
      this.setLoading(false)
      console.log('onLogout 1')
      this.$router.replace('/welcome')
    },
    openLine () {
      window.open(this.getLine)
    },
    isPageActive (pageName) {
      return (this.$route.name === pageName)
    },
    async gotoWithdraw () {
      const resp = await checkCreditTurn()
      if (resp.status_code === STATUS_SUCCESS) {
        if (resp.data.withdraw_able === false) {
          // this.setCreditTurn(resp.data.credit_turn)
          this.$router.replace('unavailablewithdraw')
        } else {
          this.$router.replace('withdraw')
        }
      }
    },
    isDisabled () {
      if (this.hasBank === null) {
        return 'disabled'
      }
      return ''
    }
  },
  computed: {
    ...mapState({
      hasBank: state => state.bankModule.hasBank,
      isMember: (state) => state.authModule.isMember,
      afKey: (state) => state.registerModule.afKey,
      agKey: (state) => state.registerModule.agKey
    }),
    ...mapGetters(['getBalance', 'getUsername', 'getLine', 'getLogo1']),
    iconsFlags () {
      return this.language === 'th' ? 'flag-icon flag-icon-th' : 'flag-icon flag-icon-gb'
    },
    settingBank () {
      return this.hasBank ? '/member/settings/bank' : '/member/settings/bank/add'
    },
    deposit () {
      return this.hasBank ? '/member/deposit' : '/member/settings/bank/add'
    }
  }
}
</script>

<style>

.x-header .-menu-wrapper .nav-link2 {
    background-image: linear-gradient(359deg, #e0e0e0, #bcbcbc), linear-gradient(180deg, #d82435, #151515) !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
    height: calc(100% + -13px) !important;
    margin-top: 9px;
}
@media screen and (max-width: 768px) {
  .carousel-view {
    display: block;
  }

  .pc-view {
    display: none !important;
  }

  .nav-slide li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    /* display: inline-block; */
    /* width: 24% !important; */
    width: 32.4% !important;
    height: auto !important;
    padding: 3px 0;
    text-align: center;
    vertical-align: top;
    margin-right: 1.5px;
    margin-left: 1.5px;
    text-indent: 0px !important;
  }

  .nav-slide {
    position: relative;
    flex-wrap: wrap;
    right: 0;
    bottom: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    /* display: inline-block; */
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
    list-style: none;
  }

  .nav-slide-yeekee li {
    position: relative;
    -ms-flex: none;
    flex: none;
    /* display: inline-block; */
    width: 11.3% !important;
    height: auto !important;
    padding: 3px 0;
    text-align: center;
    vertical-align: top;
    margin-right: 1.5px;
    margin-left: 1.5px;
    margin-bottom: 3px;
    text-indent: 0px;
  }

  .nav-slide-yeekee {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    /* display: inline-block; */
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
    list-style: none;
  }

  .nav-slide li,
  .nav-slide-yeekee li {
    cursor: pointer;
    background-color: rgba(108, 117, 125, 0.2);
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
    border-radius: 2px;
  }
}

.topnavbar {
  width: 100%;
  height: auto;

  color: white;
  padding: 8px 0;
    /* background: linear-gradient(118deg, rgb(26 74 173) 0%, rgb(13 171 254 / 55%) 100%, rgb(0 0 0 / 72%) 100%); */
    box-shadow: 0px 0px 5px #aeaeaecc;
}

.topnavbar.sticky-top {
  z-index: 998;
}

.memberlogo {
  width: auto;
  text-align: left;
}

.logomember {
  background-size: cover;
  width: 120px;
  height: 65px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 765px) {
  .logomember {
    margin-left: 0px;
  }
}
#logosymbol {
  display: none;
}

@media screen and (max-width: 321px) {
  .logomember {
    width: 35px;
    height: 35px;
    margin-top: 0px;
  }
  #logosymbol {
    display: inline-block;
    width: 100%;
    border-radius: 2px;
    box-shadow: #333 0px 0px 1px;
  }
  #logofull {
    display: none;
  }
}

.logomember img {
  width: 100%;
  padding-top: 16px;
}

#menu-profile {
  margin-left: 5px;
  padding-right: 20px;
  display: inline-block;
  cursor: pointer;
}

#menu-profile.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

#menu-profile-dropdown > .dropdown-item:hover {
  background-color: #eee !important;
}
/* menu-pc */
#menu-pc {
  vertical-align: middle;
}

#menu-pc a {
  color: #0e0250;
  text-decoration: none;
  display: inline-block;
  margin-top: 2px;
  padding: 0 10px;
  font-size: 24px;
  vertical-align: middle;
  text-shadow: 1px 1px 0 #3305d8;
}

#menu-pc a:hover,
#menu-pc a:active,
#menu-pc a.active {
  color: rgba(0, 0, 0, 1);
  text-shadow: 1px 1px 1px blue;
}

.linemenu-x {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 24px;
  min-height: 24px;
  height: auto;
  display: inline-block;
  margin-left: 5px;
  margin-right: 15px;
  margin-top: 0;
}
#sidebarCollapsePC {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  padding: 0px;
}

#sidebarCollapse {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
}

#sidebarCollapse i {
  line-height: 24px;
  margin-top: 2.5px;
  padding-right: 0px;
}
.pc-view .bootstrap-select a,
.mobile-view .bootstrap-select a {
  font-size: initial;
  text-shadow: initial;
  color: initial;
}
.pc-view .bootstrap-select,
.mobile-view .bootstrap-select {
  background: transparent;
  height: auto;
}
.pc-view .bootstrap-select .btn-sm {
  background: rgba(0, 0, 0, 0.2);
}
.mobile-view .bootstrap-select .btn-block {
  background: rgba(0, 0, 0, 0);
}
.pc-view .bootstrap-select .filter-option-inner {
  min-width: 20px;
  max-width: 20px;
}
.pc-view .bootstrap-select .filter-option-inner span,
.mobile-view .bootstrap-select .filter-option-inner span {
  border-radius: 1px;
}
.pc-view .bootstrap-select > .dropdown-toggle,
.mobile-view .bootstrap-select > .dropdown-toggle {
  padding-right: 22px;
}

.mobile-view {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .mobile-view {
    display: block !important;
  }

  .container-fluid {
    padding: 0px;
    height: auto;
    z-index: 1;
  }

  .row-menu-grid {
    border-radius: 0px;
  }

  .main-content {
    border-radius: 0px;
  }

  .txt-box-news {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .txt-box-news .d-flex {
    margin-bottom: 8px;
    padding-bottom: 10px !important;
    border-bottom: #ccc dashed 1px;
  }

  .bubblechat {
    display: none;
  }

  ul.countdown {
    list-style: none;
    margin: 0px 0;
    padding: 0;
    display: flex;
    text-align: right;
    overflow: hidden;
    height: 25px;
  }

  ul.countdown li {
    display: inline-block;
  }

  ul.countdown li span {
    font-size: 25px;
    font-weight: 300;
    line-height: 25px;
  }

  ul.countdown li.seperator {
    font-size: 15px;
    line-height: 30px;
    vertical-align: middle;
  }

  ul.countdown li p {
    color: #a7abb1;
    font-size: 14px;
  }

  .lotto-head > h5 {
    font-weight: 400 !important;
    text-align: right;
    margin-bottom: 0px;
    font-size: 15px;
  }

  .ribbon-lotto {
    background: #d21313;;
    height: 20px;
    width: auto;
    display: inline-block;
    position: absolute;
    color: #fff;
    line-height: 20px;
    padding: 0px 5px;
    margin-top: 5px;
    border-left: rgba(0, 0, 0, 0.3) 1px solid;
    font-size: 16px;
  }

  .ribbon-lotto i {
    font-size: 14px;
  }

  .ribbon-lotto:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    /* border: 10px solid #0dabfe;; */
    border: 10px solid #ffffff;
    right: -9px;
    height: 20px;
    border-left-width: 0px;
    border-right-color: transparent;
    filter: drop-shadow(6px 6px 3px rgba(0, 0, 0, 0.04));
  }

  .lotto-close .ribbon-lotto:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    border: 10px solid rgba(0, 0, 0, 1);
    right: -10px;
    height: 20px;
    border-left-width: 0px;
    border-right-color: transparent;
    filter: drop-shadow(6px 6px 3px rgba(0, 0, 0, 0.04));
  }

  .lotto-close .ribbon-lotto span.round {
    color: black;
    background: rgba(255, 255, 255, 0.5);
    padding: 0px 2px;
    padding-bottom: 2px;
  }

  .bg-option > .d-flex > .flex-fill {
    flex: 1 0 21% !important;
  }

  .bg-option .bg-btn button {
    padding: 5px;
  }
  .option2btn:active,
  .option2btn.active,
  .option2btn.focus {
    background: black !important;
    outline: #000 1px dashed !important;
    outline-offset: 1px !important;
  }

  .bar-powerball {
    display: block;
  }

  .bar-powerball-pc {
    display: none;
  }

  .btn-tanghuay {
    font-size: 12px !important;
    padding-right: 4px;
  }
  .btn-panghuay {
    font-size: 12px !important;
    padding-right: 4px;
  }
}
#re-credit {
  display: inline-block;
  cursor: pointer !important;
  font-size: 18px;
  vertical-align: middle;
  padding: 0;
}

#re-credit span {
  border: 2px solid #1004b9;
  transition: all 0.5s;
  color: #1a0079 !important;
  background: #fff !important;
  transition: all 0.5s linear, color 0.1s linear;
}

#re-credit:hover span {
  transition: all 0.5s;
  border: 2px solid #040297;
  cursor: pointer;

  background: #070250;
  color: #fff;
  transition: all 0.5s linear, color 0.1s linear;
}

#re-credit.active span {
  transition: all 0.5s;
  border: 2px solid #0c0297;
  cursor: pointer;

  background: #020350 !important;
  color: #fff !important;
  transition: all 0.5s linear, color 0.1s linear;
}

#re-credit i,
#re-credit span {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  margin-left: 0;
}

#re-credit i {
  font-size: 18px;
  background: #2504b9;
  border-radius: 18px;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}

.tooltip[x-placement^='top'] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}

.tooltip[x-placement^='bottom'] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='right'] {
  margin-left: 5px;
}

.tooltip[x-placement^='right'] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^='left'] {
  margin-right: 5px;
}

.tooltip[x-placement^='left'] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
