<template>
  <div class="Main">
    <div class="loading" v-if="isLoading">Loading&#8230;</div>
    <app-toast />
    <app-good/>
    <div id="wrapper">
      <div class="fixed-bg2"></div>
      <app-header-member />
      <app-sidebar v-if="isMember"/>
      <!-- <app-header /> -->
      <div class="row p-0 m-0 main_">
        <!-- <app-banner style="border-radius: 20px !important;" class="w-100" v-b-modal.modal-news-agent /> -->
        <!-- <app-header class=""/> -->
        <app-login v-if="!isMember" />
        <app-register v-if="!isMember && (BYPASS_OTP == 0)" />
        <app-register-no-otp v-if="!isMember && (BYPASS_OTP == 1)" />
        <app-register_success v-if="isMember" />
        <router-view :class="[this.$route.meta.isSubLayout ? 'col-md-8 col-sm-12 p-1': 'col-12']" ></router-view>
      </div>
      <app-footer />
    </div>
    <app-box-vip v-bind:vip_level="getVip" v-bind:balance="getCreditTotal" v-bind:username="getUser" v-bind:credit_total="getCreditTotal" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Sidebar from '@/components/Sidebar'
// import subLayout from '@/components/subLayout'
import HeaderMember from '@/components/HeaderMember'
// import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Toast from '@/components/toastalerts/Toast'
import Good from '@/components/toastalerts/Good'
// import banner from '@/components/banner'
import login from '@/components/login'
import register_success from '@/components/register_success'

// VUE_APP_EXT_NAME
import register from '@/components/register3'
// import register from '@/components/register'
import register_no_otp from '@/components/registerNoOtp'
import BoxVip from '@/components/box_vip'

const nonFooterPath = ['register-verify', 'register-form', 'register-success']

export default {
  name: 'Main',
  data () {
    return {
      BYPASS_OTP: process.env.VUE_APP_BYPASS_OTP
    }
  },
  components: {
    'app-box-vip': BoxVip,
    'app-sidebar': Sidebar,
    'app-header-member': HeaderMember,
    // 'app-header': Header,
    'app-footer': Footer,
    'app-toast': Toast,
    'app-good': Good,
    // 'app-banner': banner,
    // 'app-header': Header,
    'app-login': login,
    'app-register': register,
    'app-register-no-otp': register_no_otp,
    'app-register_success': register_success
  },
  created () {
    this.changeFooter()
  },
  updated () {
    this.changeFooter()
  },
  computed: {
    ...mapState({
      isMember: (state) => state.authModule.isMember,
      isLoading: (state) => state.homeModule.isLoading
    }),
    ...mapGetters([
      'getAffiliateLink',
      'getCurrentIncome',
      'getTotalClick',
      'getTotalIncome',
      'getDownline',
      'getTotalBet',
      'getNews',
      'getAgentNews'
    ]),
    getBalance () {
      return this.$store.getters.getBalance
    },
    getUser () {
      return this.$store.getters.getUsername
    },
    getCreditTotal () {
      return this.$store.getters.getCreditTotal
    },
    getVip () {
      return this.$store.getters.getVip
    },
    getIsVip () {
      return this.$store.getters.getIsVip
    }
  },
  methods: {
    changeFooter () {
      const pathName = window.location.pathname
      nonFooterPath.some((path) => pathName.includes(path))
        ? (this.isShowFooter = false)
        : (this.isShowFooter = true)
    }
  }
}
</script>

<style>

.main_{
  padding-bottom: 100px !important;
}
body{
  /* background: linear-gradient(90deg, var(--topbarBg) -0.7%, #0a185100 113.23%); */
  /* background: linear-gradient(90deg, #7a7a7a -0.7%, #016cbc70 113.23%); */

    /* background-color: transparent !important; */
}

.main-content{
    margin-top: 10px !important;
}
.bg-light {
  border-radius: 4px;
        /* background-color: #f8f9fa00 !important; */
    }
</style>
