<template>
  <transition name="fade">
    <div
      id="toast-container"
      class="toast-top-right"
      :class="{ show: activeInfo }"
      v-show="activeInfo"
      @click="setActiveInfo(false)"
    >
      <div class="toast toast-info" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-body">
          <div class="toast-title">{{ getMessage() }}</div>
          <div class="toast-message">{{ getInfo() }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isEmpty } from '@/utils/'
export default {
  name: 'Toast',
  computed: {
    ...mapState({
      infoMessage: state => state.errorModule.infoMessage,
      activeInfo: state => state.errorModule.activeInfo
    })
  },
  methods: {
    ...mapActions(['setActiveInfo']),
    getInfo () {
      return isEmpty(this.infoMessage) ? 'ดำเนินการสำเร็จ' : this.infoMessage
    },
    getMessage () {
      return isEmpty(this.infoMessage) ? 'Success' : 'Warning'
    }
  }
}
</script>

<style scoped>
.toast-top-right {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
}
.toast-title {
  font-weight: bold;
  color: #ffffff;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  color: #ffffff;
}
.toast-success {
  background-color: #51a351;
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: rgba(31, 49, 84, 1);
}
.toast-warning {
  background-color: #f89406;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
