<template>
  <b-modal id="modal-user-register_success"
    title="เข้าสู่ระบบ"
    hide-footer hide-header
    class="p-0 bg_tran">
    <div class="modal-content2">
        <div class="modal-header">
          <div class="modal-close" @click="$bvModal.hide('modal-user-register_success')">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
          <div class="modal-header-inner">
            <div class="btn-group w-100" role="group">
              <button type="button" class="btn btn-success ">สมัครสมาชิกเรียบร้อย</button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <img src="@/assets/new/Login.webp" loading="lazy" alt="" width="70%">
          <div class="bg-light border border-primary text-center pt-2 mb-2  text-black">
            <h5 class="text-primary">ข้อมูลสมาชิกของท่าน</h5>
            <hr />
            <center>
              <table>
                <tbody>
                  <tr>
                    <td><span class="badge badge-primary w-100">ชื่อผู้ใช้</span></td>
                    <td class="pl-2 text-black">{{ username }}</td>
                  </tr>
                  <tr>
                    <td><span class="badge badge-primary w-100">รหัสผ่าน</span></td>
                    <td class="pl-2  text-black">{{ password }}</td>
                  </tr>
                </tbody>
              </table>
            </center>
            <br />
            กรุณาจด ข้อมูลไว้เพื่อใช้เข้าเล่นครั้งต่อไป<br />
            <div
              class="bg-primary p-1 mt-2 text-light d-flex flex-column flex-sm-column flex-md-row justify-content-center"
            >
              <span class="mx-1 w-100">กรุณาเก็บข้อมูลของท่าน เพื่อใช้ในการเข้าเล่นครั้งต่อไป</span>
            </div>
          </div>
        </div>
      </div>
  </b-modal>
</template>
<script>
export default {
  data () {
    return {
      language: localStorage.getItem('locale') ?? 'th',
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password')
    }
  }
}
</script>
<style lang="less">
#modal-user-login{
height: 100% !important;
}
#modal-user-register{
height: 100% !important;
}
#modal-user-register_success{
height: 100% !important;
}
.modal-content {
    // background-color: transparent !important;
    background: var(--bodyBg) !important;
}
.bgModalHeaderLogin {
  background: linear-gradient(180deg, var(--color-1-main));
}

.bg-primary-login {
  // background: linear-gradient( 225deg, #420000 0%, rgb(144 3 34) 40%, #420000 100%);
  background: linear-gradient(132deg, #ce878e 0%, rgb(140 140 140) 40%, rgb(152 21 21) 100%);
  border-width: 3px;
  border-style: solid;
  border-color: #7a3438;
  border-radius: 5px;
  // background-image: linear-gradient(#b5b4b4 1px, transparent 1px), linear-gradient(to right, #c8a042 1px, #c8a042 1px);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
u,
div {
  font-family: Kanit, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.container-login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  // background: -webkit-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: -o-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: -moz-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  top: 0;
  left: -100%;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
}

.login100-form-btn {
  background: #900322;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}
</style>
