<template>
  <div class="CallCenterModal">
    <div
      class="modal fade"
      id="CallCenterModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="CallCenterModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="border-radius: 10px">
          <div class="modal-header bg-dark-blue text-white px-3 py-2">
            <p class="modal-title">ช่องทางการติดต่อ</p>
            <button
              type="button"
              class="text-white close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <a
                  href="javascript:;" @click="openLine()"
                  class="btn btn-outline-success btn-block btn-contactbox mb-3 bg-wjite"
                >
                  <span><small>ติดต่อผ่านไลน์</small></span>
                  <i class="fab fa-line"></i>
                </a>
              </div>
              <!-- <div class="col-6 col-sm-6 col-md-6">
                <a
                  href="#"
                  onclick="LC_API.open_chat_window();return false"
                  class="btn btn-outline-primary btn-block btn-contactbox mb-0"
                >
                  <span><small>แชทสดกับพนักงาน</small></span>
                  <i class="fa fa-user"></i>
                </a>
              </div> -->
              <!-- <div class="col-6 col-sm-6 col-md-6">
                <a
                  href="tel:0924034481"
                  class="btn btn-outline-info btn-block btn-contactbox mb-3"
                >
                  <span><small>ติดต่อผ่านโทรศัพท์</small></span>
                  <i class="fas fa-phone-square"></i>
                </a>
              </div> -->
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button
              type="button"
              class="btn btn-dark-blue"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  methods: {
    openLine () {
      window.open(this.getLine)
    }
  },
  computed: {
    ...mapGetters(['getLine']),
    iconsFlags () {
      return this.language === 'th' ? 'flag-icon flag-icon-th' : 'flag-icon flag-icon-gb'
    },
    settingBank () {
      return this.hasBank ? '/member/settings/bank' : '/member/settings/bank/add'
    },
    deposit () {
      return this.hasBank ? '/member/deposit' : '/member/settings/bank/add'
    }
  }
}
</script>

<style>
.bg-wjite{
  background: white;
}
.btn-outline-success {
    color: #28a745 !important;
    border-color: #28a745 !important;
}
</style>
