<template>
  <div class="comp-footer x-button-actions" id="account-actions-mobile" v-if="!(this.$route.meta.isNoRequestFooter == true)">
    <div class="footer-wrap" v-if="!isMember">
      <button class="footer-menu" v-b-modal.modal-user-login>
        <img src="@/assets/icon/login.png" class="footer-icon">
        <p class="footer-name">เข้าสู่ระบบ</p>
      </button>
      <button class="footer-menu" @click="openRegister">
        <img src="@/assets/icon/register.png" class="footer-icon">
        <p class="footer-name">สมัครสมาชิก</p>
      </button>
      <button class="footer-menu main-home" @click="pushRoute('/')">
        <div class="main-home-content"  v-if="AGENT_S_BASE=='GIANT999' || AGENT_S_BASE=='WISDOMBET' || AGENT_S_BASE=='PYN' || AGENT_S_BASE=='NV24'">
          <img :src="require('@/assets/images/'+getLogo2)" style="width:100%">
        </div>
        <div class="main-home-content"  v-else-if="AGENT_S_BASE=='TGONE'">
          <img :src="require('@/assets/images/'+getLogo2)" style="width:100%">
          <p class="footer-name m-0">หน้าหลัก</p>
        </div>
        <div class="main-home-content" v-else>
          <img :src="require('@/assets/images/'+getLogo2)" style="width:60%">
          <p class="footer-name m-0">หน้าหลัก</p>
        </div>

      </button>
      <button class="footer-menu" @click="pushRoute('/promotions')">
        <img src="@/assets/icon/loudspeaker.png" class="footer-icon">
        <p class="footer-name">โปรโมชัน</p>
      </button>
      <button class="footer-menu" @click="openLine()" >
        <img src="@/assets/icon/line2.png" class="footer-icon">
        <p class="footer-name">ติดต่อเรา</p>
      </button>
    </div>
    <div class="footer-wrap" v-else>
      <button class="footer-menu" @click="pushRoute((hasBank ? '/member/deposit' : '/member/settings/bank/add'))">
        <img src="@/assets/icon/deposit.png" class="footer-icon">
        <p class="footer-name">ฝากเงิน</p>
      </button>
      <button class="footer-menu"  @click="pushRoute((hasBank ? '/member/withdraw' : '/member/settings/bank/add'))">
        <img src="@/assets/icon/money-withdrawal.png" class="footer-icon">
        <p class="footer-name">ถอนเงิน</p>
      </button>
      <button class="footer-menu main-home" @click="pushRoute('/')">
        <div class="main-home-content">
          <div class="main-home-content"  v-if="AGENT_S_BASE=='GIANT999' || AGENT_S_BASE=='WISDOMBET' || AGENT_S_BASE=='PYN' || AGENT_S_BASE=='NV24'">
          <img :src="require('@/assets/images/'+getLogo2)" style="width:100%">
        </div>
        <div class="main-home-content"  v-else-if="AGENT_S_BASE=='TGONE'">
          <img :src="require('@/assets/images/'+getLogo2)" style="width:100%">
          <p class="footer-name m-0">หน้าหลัก</p>
        </div>
        <div class="main-home-content" v-else>
          <img :src="require('@/assets/images/'+getLogo2)" style="width:60%">
          <p class="footer-name m-0">หน้าหลัก</p>
        </div>
        </div>
      </button>
      <button class="footer-menu" @click="pushRoute('/member/affiliate')">
        <img src="@/assets/icon/salary.png"  class="footer-icon">
        <p class="footer-name">แนะนำเพื่อน</p>
      </button>
      <button class="footer-menu" @click="openLine()" >
        <img src="@/assets/icon/line2.png" class="footer-icon">
        <p class="footer-name">ติดต่อเรา</p>
      </button>
    </div>
  </div>
  <!-- <div class="x-button-actions" id="account-actions-mobile">
    <div class="-outer-wrapper" v-if="!isMember">
      <div class="-left-wrapper">
        <a href="javascript:;" class="-item-wrapper -log-blink" v-b-modal.modal-user-login>
          <img src="@/assets/icon/login.png" class="-ic-img" alt=" Casino รูปไอคอนไลน์" width="34" height="34">
          <span class="-text d-none d-sm-inline-block">เข้าสู่ระบบ</span>
          <span class="-text d-sm-none">เข้าสู่ระบบ</span>
        </a>
        <router-link to="/register-verify" class="-item-wrapper -log-blink wpGlobalA">
          <img src="@/assets/icon/register.png" class="-ic-img" alt=" Casino รูปไอคอนไลน์" width="34" height="34">
          <span class="-text d-none d-sm-inline-block">สมัครสมาชิก</span>
          <span class="-text d-sm-none">สมัครสมาชิก</span>
        </router-link>
      </div>
      <router-link to="/" class="-center-wrapper -log-blink wpGlobalA">
        <div class="-selected -item-wrapper -shimmer -register -main-logo xx"
          :style="{ 'background-image': 'url(' + require('@/assets/home.png') + ')' }">
        </div>
      </router-link>
      <div class="-right-wrapper">
        <router-link to="/promotions" class="-item-wrapper -promotion -log-blink">
          <img src="@/assets/icon/loudspeaker.png" class="-ic-img" alt=" Casino รูปไอคอนโปรโมชั่น" width="34" height="34">
          <span class="-text">โปรโมชั่น</span>
        </router-link>
        <a href="javascript:;" @click="openLine()" class="-item-wrapper -log-blink" rel="noopener noreferrer">
          <img src="@/assets/icon/line2.png" class="-ic-img" alt="แนะนำเพื่อน" width="34" height="34">
          <span class="-text">ติดต่อเรา</span>
        </a>
      </div>
      <div class="-fully-overlay js-footer-lobby-overlay"></div>
    </div>
    <div class="-outer-wrapper" v-else>
      <div class="-left-wrapper">
        <router-link :to="hasBank ? '/member/deposit' : '/member/settings/bank/add'" class="-item-wrapper -log-blink">
          <img src="@/assets/icon/deposit.png" class="-ic-img" alt=" Casino รูปไอคอนไลน์" width="34" height="34">
          <span class="-text">ฝากเงิน</span>
        </router-link>
        <router-link :to="hasBank ? '/member/withdraw' : '/member/settings/bank/add'"
          class="-item-wrapper -log-blink wpGlobalA">
          <img src="@/assets/icon/money-withdrawal.png" class="-ic-img" alt=" Casino รูปไอคอนไลน์" width="34" height="34">
          <span class="-text">ถอนเงิน</span>
        </router-link>
      </div>
      <router-link to="/" class="-center-wrapper -log-blink wpGlobalA">
        <div class="-selected -item-wrapper -shimmer -register -main-logo xx"
          :style="{ 'background-image': 'url(' + require('@/assets/home.png') + ')' }">
        </div>
      </router-link>
      <div class="-right-wrapper">
        <router-link to="/member/affiliate" class="-item-wrapper -promotion -log-blink">
          <img src="@/assets/icon/salary.png" class="-ic-img" alt=" Casino รูปไอคอนโปรโมชั่น" width="34" height="34">
          <span class="-text">แนะนำเพื่อน</span>
        </router-link>
        <a href="javascript:;" @click="openLine()" class="-item-wrapper -log-blink" rel="noopener noreferrer">
          <img src="@/assets/icon/line2.png" class="-ic-img" alt="แนะนำเพื่อน" width="34" height="34">
          <span class="-text">ติดต่อเรา</span>
        </a>
      </div>
      <div class="-fully-overlay js-footer-lobby-overlay"></div>
    </div>
  </div> -->
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState({
      hasBank: state => state.bankModule.hasBank,
      isGamePermission: (state) => state.authModule.isGamePermission,
      isMember: (state) => state.authModule.isMember
    }),
    ...mapGetters(['getLine', 'getLogo2'])
  },

  data () {
    return {
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE,
      casinoEnable: process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim(),
      AGENT_EXT_NAME: process.env.VUE_APP_EXT_NAME
    }
  },
  methods: {
    openLine () {
      window.open(this.getLine)
    },
    pushRoute (route) {
      this.$router.replace(route)
      this.setIsActive(false)
    },
    openRegister () {
      this.$bvModal.hide('modal-user-login')
      this.$bvModal.show('modal-user-register')
    }
  }
}
</script>

<style>
.main-img-footerBar {
  max-width: 70px;
  z-index: 11;
  box-shadow: 0px 0px 10px #161f3099;
  border-radius: 100px;
}

img.nav-box-cycle {
  border: 4px solid #004eb7;
  display: inline-block;
  border-radius: 60px;
  padding: 5px;

  min-width: 60px;
  min-height: 60px;
  max-width: 60px !important;
  max-height: 60px !important;
  background-color: #005623;
}

.fa-star {
  /*color : #0354f7 !important;
  text-shadow: 5px 5px 15px #ead99e !important;*/
}

.navbaBT>div>ul>li {
  box-sizing: border-box;
  list-style-type: none;
  padding: 0px;
}

.navbaBT>ul>li {
  box-sizing: border-box;
  list-style-type: none;
  padding: 0px;

}

.navbaBT_ul_li_last {
  /* border-right: 1px solid #47494cb8; */
}

.navbaBT>ul>li>a {
  padding: 5px 0 5px 0;
}

.navbaBT ul {
  display: flex;
  justify-content: center;
  position: static;
  margin: 0px;
  justify-content: flex-end;
}

.navbaBT {
  z-index: 10;
  /* overflow: hidden; */
  /*background: linear-gradient(143deg, #005623 51%, rgba(1,70,29,1) 71%, rgba(25,161,80,1) 100%);*/
  /*background: linear-gradient(90deg, rgba(11,19,32,1) 0%, rgba(7,19,20,1) 100%, rgba(0,212,255,1) 100%);*/
  /*background:linear-gradient(90deg, rgb(24 83 203 / 76%) 0%, rgb(13 171 254 / 50%) 100%, rgba(0,212,255,1) 100%);*/
  background: linear-gradient(90deg, rgb(38 93 183) 0%, rgb(24 83 203) 100%, rgb(17 32 60) 100%);
  /* background-color: #005623; */
  /* background: linear-gradient(143deg, #005623 10%, rgba(1,70,29,1) 35%, rgba(24,161,80,1) 100%); */
  /* background: linear-gradient(143deg, rgba(24,161,80,1) 0%, rgba(3,61,26,1) 33%, rgba(1,70,29,1) 64%, rgba(24,161,80,1) 100%); */
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #7fcef8;
}

.navbaBT a {
  /*float: left;*/
  flex-direction: column;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbaBT a:hover {
  /*background: #f1f1f1;*/
  color: black;
}

.navbaBT a.active {
  background-color: #4CAF50;
  color: white;
}

.copyright {
  display: flex;
  justify-content: center;
  background: rgba(31, 49, 84, 1);
  color: rgba(255, 255, 255, 1);
}
/* new footer */
.comp-footer {
    box-shadow: 0 -2px 10px rgba(0,0,0,.5);
}
.comp-footer{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 70px;
    border-radius: 32px 32px 0 0;
    display: flex;
    justify-content: center;
}
.comp-footer .footer-wrap {
    display: grid;
    grid-template-columns: repeat(5,minmax(0,1fr));
    max-width: 960px;
    width: 100%;
    height: 100%;
}
.comp-footer .footer-wrap .footer-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    background-image: none;
}
.comp-footer .footer-wrap .footer-menu.main-home .main-home-content {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 100px;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.comp-footer .footer-wrap .footer-menu.main-home .main-home-content {
    /* background-color: rgba(218,30,55,.6); */
    background-color: rgb(0 0 0 / 60%);
    /* background: linear-gradient(90deg, rgb(38 93 183) 0%, rgb(24 83 203) 100%, rgb(17 32 60) 100%); */
}
.comp-footer .footer-wrap .footer-menu.main-home .main-home-content .main-home-icon{
    width: 50px;
    height: 50px;
    -webkit-mask: url(../assets/menu_center/home.svg);
    mask: url(../assets/menu_center/home.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}
.comp-footer .footer-wrap .footer-menu.main-home .main-home-content .main-home-icon {
    background: #fff;
}
.comp-footer .footer-wrap .footer-menu .footer-icon {
    width: auto;
    height: 25px;
}
.footer-name{
  margin: 0px;
  color: white;
}
@media screen and (max-width: 500px) {
  .comp-footer .footer-wrap .footer-menu.main-home .main-home-content .main-home-icon {
    width: 40px;
    height: 40px;
  }
  .comp-footer {
    height: 60px;
  }
  .comp-footer .footer-wrap {
    display: flex;
  }
  .comp-footer .footer-wrap .footer-menu {
    width: calc(20% - 5px);
  }
  .comp-footer .footer-wrap .footer-menu .footer-icon {
    width: auto;
    height: 22px;
  }
  .comp-footer .footer-wrap .footer-menu .footer-name{
    font-size: 12px;
    margin-top: 2px;
  }
  .comp-footer .footer-wrap .footer-menu .footer-name{
    font-size: 13px;
    font-weight: 300;
    margin-top: 4px;
  }
  .comp-footer .footer-wrap .footer-menu .footer-name {
    color: #fff;
  }
  .comp-footer .footer-wrap .footer-menu.main-home {
    width: calc(20% + 20px);
  }
  .comp-footer .footer-wrap .footer-menu.main-home .main-home-content{
    bottom: 0;
    width: 88px;
    height: 88px;
  }
}
</style>
