<template>
  <div class="sidebarcomp">
    <nav id="sidebar" :class="{ active: getIsActive }" style="overflow: visible">
      <div style="max-height: none">
        <div style="position: relative; top: 0; left: 0" dir="ltr">
          <div id="dismiss" @click="setIsActive(false)">
            <i class="fas fa-backspace fa-flip-horizontal"></i>
          </div>
          <div class="sidebar-header">
            <div class="align-center text-center">
                <a href="#" v-if="getIsVip">
                <span @click="pushRoute('/member/vip')" v-if="getIsVip" class="username text-white" style="line-height: 1;word-spacing: 20px;text-shadow: 0 0 5px #f7da74;"><i class="fas fa-crown icon-vip"></i>VIP {{ this.$store.getters.getVip }}</span>
                </a>
                <br />
                 <br />
              <div class="m-user-name-icon">{{ (getUsername.charAt(0)+getUsername.charAt(getUsername.length - 1)).toUpperCase() }}</div>
              <br />
              <span class="username">{{ getUsername }}</span>
                  <br />
              <span class="badge badge-pill badge-light thb credit_display" data-id="credit_balance">{{ getBalance }} ฿</span>
              <br />
            </div>
          </div>
          <ul class="list-unstyled components">
            <li>
              <span @click="pushRoute('/setting/profile')">
                 <img src="@/assets/icon/management.png" class="-icon icon_mb_menu" alt="Menu icon promotion">
                {{ $t('accountsetting') }}
              </span>
            </li>
            <li>
              <span @click="pushRoute(settingBank)">
                <img src="@/assets/icon/bank.png" class="-icon icon_mb_menu" alt="Menu icon promotion">
                {{ $t('bankaccount') }}
              </span>
            </li>
            <li>
              <span @click="pushRoute('/member/games/history')">
                <img src="@/assets/icon/board-game.png" class="-icon icon_mb_menu" alt="Menu icon promotion">ประวัติเกม</span>
            </li>
            <li>
              <span @click="pushRoute('/member/numbersets')">
                <img src="@/assets/icon/file.png" class="-icon icon_mb_menu" alt="Menu icon promotion">สร้างเลขชุด</span>
            </li>
            <li>
              <span @click="pushRoute('/member/statement')">
                <img src="@/assets/icon/statement.png" class="-icon icon_mb_menu" alt="Menu icon promotion">เครดิต</span>
            </li>
            <li>
              <span @click="pushRoute('/member/request/all')">
                <img src="@/assets/icon/money-bag.png" class="-icon icon_mb_menu" alt="Menu icon promotion">ฝากถอน</span>
            </li>
            <li>
              <span @click="pushRoute('/member/affiliate')">
                <img src="@/assets/icon/salary.png" class="-icon icon_mb_menu" alt="Menu icon promotion">แนะนำเพื่อน</span>
            </li>
            <li>
              <span @click="pushRoute('/member/poy')">
                <img src="@/assets/icon/ticket.png" class="-icon icon_mb_menu" alt="Menu icon promotion">รายการโพย</span>
            </li>
            <li>
              <span @click="pushRoute('/member/result/all')">
                <img src="@/assets/icon/lottery.png" class="-icon icon_mb_menu" alt="Menu icon promotion">ผลรางวัล</span>
            </li>
            <li>
              <router-link to="javascript:;" data-toggle="modal" data-target="#CallCenterModal">
                <span>
                  <i class="fas fa-handshake"></i>
                  {{ $t('callcenter') }}
                </span>
              </router-link>
            </li>
            <!-- <div class="mobile-view">
              <div class="dropdown bootstrap-select form-control">
                <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="button">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <span v-bind:class="iconsFlags" />
                      {{ language === 'th' ? 'ไทย' : 'English' }}
                    </div>
                  </div>
                  &nbsp;<span class="bs-caret"><span class="caret"></span></span>
                </button>
                <div class="dropdown-menu">
                  <button class="dropdown-item" style="cursor: pointer" @click="selectLangauge('th')">
                    <span class="flag-icon flag-icon-th"></span> ไทย
                  </button>
                  <button class="dropdown-item" style="cursor: pointer" @click="selectLangauge('en')">
                    <span class="flag-icon flag-icon-gb"></span> English
                  </button>
                </div>
              </div>
            </div> -->
          </ul>
          <ul class="list-unstyled CTAs">
            <li>
              <span class="logout" @click="onLogout">
                <i class="fas fa-sign-out-alt"></i>
                {{ $t('logout') }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div style="display: none">
        <div class="mCSB_draggerContainer">
          <div class="mCSB_dragger" style="position: absolute; min-height: 50px; height: 0px; top: 0px">
            <div class="mCSB_dragger_bar" style="line-height: 50px"></div>
          </div>
          <div class="mCSB_draggerRail"></div>
        </div>
      </div>
    </nav>
    <app-callcenter-modal />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CallCenterModal from '@/components/modals/CallCenterModal'
export default {
  components: {
    'app-callcenter-modal': CallCenterModal
  },
  data: function () {
    return {
      language: localStorage.getItem('locale') ?? 'th'
    }
  },
  computed: {
    ...mapState({
      hasBank: state => state.bankModule.hasBank
    }),
    ...mapGetters(['getBalance', 'getUsername', 'getIsActive', 'getUsetAgentId']),
    iconsFlags: function () {
      return this.language === 'th' ? 'flag-icon flag-icon-th' : 'flag-icon flag-icon-gb'
    },
    settingBank () {
      return this.hasBank ? '/member/settings/bank' : '/member/settings/bank/add'
    },
    getVip () {
      return this.$store.getters.getVip
    },
    getIsVip () {
      return this.$store.getters.getIsVip
    }
  },
  methods: {
    ...mapActions(['reqConfig', 'setIsActive', 'clearLogOut', 'setLoading', 'clearAfKey', 'clearAgKey']),
    selectLangauge (lang) {
      this.language = lang
      this.$i18n.locale = lang?.toLowerCase()
      localStorage.setItem('locale', lang?.toLowerCase())
    },
    onLogout () {
      this.setIsActive(false)
      this.setLoading(true)
      this.clearLogOut()
      this.clearAfKey()
      this.clearAgKey()
      this.setLoading(false)
      console.log('onLogout 2')
      this.reqConfig(process.env.VUE_APP_AG_ID)
      if (this.getUsetAgentId === '') {
        this.$router.replace('/welcome')
      } else {
        this.$router.replace('login')
      }
    },
    pushRoute (route) {
      this.$router.replace(route)
      this.setIsActive(false)
    }
  }
}
</script>

<style>
.m-user-name-icon{
  background: linear-gradient(90deg, #7a7a7a -0.7%, #016cbc70 113.23%);
    display: inline-flex;
    width: 70px;
    height: 70px;
    color: white;
    border: 2px solid var(--gradientSubColor2);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 500;
}
.icon_mb_menu{
  width: 25px;
  height: 25px;
}
.credit_display{
  font-size: 1.3em;
}
#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  right: -257px;
  height: 100vh;
  z-index: 1023;
  background: #f8f9fa;
  color: #888;
  transition: all 0.3s;
  overflow-y: scroll;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
#sidebar.active {
  right: 0;
}
#sidebar .sidebar-header {
  padding: 20px;
  background: linear-gradient(180deg, var(--color-b1-main), var(--color-b2-main));
  font-size: 16px !important;
  box-shadow: #c1c1c1 0 1px 3px;
  z-index: 998;
  position: relative;
  color: #fff;
}
#sidebar .sidebar-header img {
  border: 3px solid white;
  background: white;
  box-shadow: #500202 0px 2px 1px;
}
#sidebar ul.components {
  padding: 0px 0;
  border-bottom: 1px solid #ddd;
  background: #ececec;
  z-index: 997;
  position: relative;
}
#sidebar ul p {
  color: #f8f9fa;
  padding: 10px;
}
#sidebar ul li span {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 1.1em;
  display: block;
  text-decoration: none;
  color: #555;
  text-shadow: #fff 1px 1px 0;
  border-bottom: #fff 1px dashed;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
#sidebar ul li span img {
  margin-right: 5px;
}
#sidebar ul li span:hover {
  color: rgba(31, 49, 84, 1);
  background: white;
  border-bottom: #f8f9fa 1px dashed;
}
#sidebar ul li:last-child span {
  border-bottom: #fff 1px solid;
}
#sidebar ul li.active > span,
#sidebar span[aria-expanded='true'] {
  color: #1004b9;
  background: white;
}
#sidebar span[data-toggle='collapse'] {
  position: relative;
}
#sidebar ul ul span {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #f8f9fa;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
#sidebar ul.CTAs {
  padding: 10px 20px;
}
#sidebar ul.CTAs span {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  /* border: #1004b9 solid 1px; */
}
/* #sidebar ul.CTAs span:hover {
  border: #1004b9 solid 1px;
} */
#dismiss {
  color: rgb(55, 97, 146);
  text-align: center;
  position: absolute;
  top: 0px;
  left: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 36px;
  z-index: 999;
}
#dismiss:hover {
  color: #f8f9fa;
}
span.logout {
  background: rgba(31, 49, 84, 1) !important;
  color: #fff !important;
  /* border: #1004b9 solid 1px; */
  text-shadow: #500202 1px 1px 0 !important;
}
span.logout:hover {
  background: #fff !important;
  color: rgba(31, 49, 84, 1) !important;
  /* border: #1004b9 solid 1px; */
  text-shadow: #fff 1px 1px 0 !important;
}
</style>
